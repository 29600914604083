



























































































































































































































































































































import Component from 'vue-class-component';
import Vue from 'vue';
import {Icons} from '@/shared/components/icon/icons';
import RsButton from '@/shared/components/buttons/button.vue';
import RsLoading from '@/shared/components/loading/loading.vue';
import RsIcon from '@/shared/components/icon/icon.vue';
import {Action, Getter} from 'vuex-class';
import JobsModule from '@/modules/jobs';
import {IApiJob} from '@/shared/models/IApiJob';
import {PublicJobsRouter} from '@/modules/jobs/router';
import {PublicJobsGetters} from '@/modules/jobs/store/getters';
import {CodetableResult, Codetables, CompanySize} from '@/shared/api/modules/codetable.api.service';
import {IApiJobSectionData} from '@/shared/models/IApiJobData';
import {DATE_SERVICE} from '@/shared/services/date.service';
import {API_SERVICE} from '@/shared/api/api.service';
import {PublicJobsActions} from '@/modules/jobs/store/actions';
import {JobBenefitsData} from '@/modules/dashboard/modules/jobs/constants/JobBenefitsData';
import RsModalSignup from '@/modules/jobs/components/signup-modal.vue';
import {LOCALSTORAGE} from '@/shared/constants/LOCALSTORAGE';

@Component({
  components: {RsModalSignup, RsIcon, RsLoading, RsButton}
})
export default class JobsSingle extends Vue {
  @Action(PublicJobsActions.GET_JOB, {namespace: JobsModule.namespace}) getJob: (jobId: string) => Promise<IApiJob>;

  @Getter(PublicJobsGetters.GET_JOB, {namespace: JobsModule.namespace}) job: IApiJob;

  JobsRouter = PublicJobsRouter;
  Icons = Icons;
  JobBenefitsData = JobBenefitsData;

  codetables: CodetableResult = {}

  id: string;
  loading = false;

  isSignUpModalOpen = false;

  get sortedSections(): IApiJobSectionData[] {
    return this.job.data.textSections?.sort((a, b) => a.order - b.order);
  }

  get hasLocationRequirements(): boolean {
    return this.job.data.countries !== undefined && this.job.data.countries.length > 0;
  }

  get locationRequirements(): string[] {
    if (this.hasLocationRequirements) {
      return this.job.data.countries as string[];
    }

    return [];
  }

  get isNew(): boolean {
    if (this.job) {
      return DATE_SERVICE.diff(this.job.createdAt, 'day') > -14
    }
    return false;
  }

  get companySizeLabel(): CompanySize | undefined {
    if (this.codetables?.company_sizes) {
      return (this.codetables.company_sizes as CompanySize[])
        .find((companySize) => companySize.name === this.job.company.data.headcount);
    }
  }

  apply() {
    localStorage.setItem(LOCALSTORAGE.JOB_APPLY, this.id)
    this.$track.sendEvent('JobApplySignup', {id: this.id})
    this.isSignUpModalOpen = true;
  }

  formatSalary(salary: number) {
    if (salary % 1000 > 0) {
      return `${Math.floor(salary / 1000)}.${Math.floor(salary % 1000 / 100)}`
    }
    return Math.floor(salary / 1000);
  }

  created() {
    this.id = this.$route.params.id;
    if (!this.job || this.job.id !== this.id) {
      this.loading = true;
    }
    this.getJob(this.id)
      .finally(() => {
        this.loading = false;
      })
  }

  async mounted() {
    this.id = this.$route.params.id;
    this.$track.sendEvent('JobSingle', {isPublic: true, id: this.id})
    if (!this.job || this.job.id !== this.id) {
      this.loading = true;
    }
    this.getJob(this.id)
      .finally(() => {
        this.loading = false;
      })

    this.codetables = await API_SERVICE.codetable.getCodetables(Codetables.COMPANY_SIZES);
  }
}
