import {JobBenefits} from '@/modules/dashboard/modules/jobs/constants/JobBenefits';
import {Icons} from '@/shared/components/icon/icons';

export const JobBenefitsData: Record<JobBenefits, {
  title: string,
  description: string,
  icon: Icons,
}> = {
  [JobBenefits.FLEXIBLE_HOURS]: {
    title: 'Flexible hours',
    description: 'Flexible schedule and freedom for attending family needs or personal errands.',
    icon: Icons.FLEXIBLE_HOURS
  },
  [JobBenefits.PERFORMANCE_BONUS]: {
    title: 'Performance bonus',
    description: 'Extra compensation for exceeding expectations. ',
    icon: Icons.PERFORMANCE_BONUS
  },
  [JobBenefits.RETIREMENT_PLAN]: {
    title: '401k/Retirement Plan',
    description: 'Company covers or matches payments for retirement plans such as 401(k) or other.',
    icon: Icons.RETIREMENT_PLAN
  },
  [JobBenefits.CHILDCARE_ASSISTANCE]: {
    title: 'Childcare Assistance',
    description: 'Company covers or copays covers free childcare during the workday.',
    icon: Icons.CHILDCARE_ASSISTANCE
  },
  [JobBenefits.HEALTH_COVERAGE]: {
    title: 'Health coverage',
    description: 'Company covers or copays health insurance or health plans.',
    icon: Icons.HEALTH_COVERAGE
  },
  [JobBenefits.COMPUTER]: {
    title: 'Computer',
    description: 'Provided computer and/or computer equipment for work.',
    icon: Icons.COMPUTER
  },
  [JobBenefits.COMPANY_RETREATS]: {
    title: 'Company retreats',
    description: 'Organized retreats by company on remote locations. ',
    icon: Icons.COMPANY_RETREATS
  },
  [JobBenefits.DIGITAL_LIBRARY]: {
    title: 'Digital Library',
    description: 'Budget for books or digital magazines subscriptions.',
    icon: Icons.DIGITAL_LIBRARY
  },
  [JobBenefits.EDUCATION_STIPEND]: {
    title: 'Education Stipend',
    description: 'Company covers or copays educational expenses connected to the position.',
    icon: Icons.EDUCATION_STIPEND
  },
  [JobBenefits.EQUITY]: {
    title: 'Equity',
    description: 'Position includes equity compensation (in various form) next to base salary. ',
    icon: Icons.EQUITY
  },
  [JobBenefits.PAID_SICK_DAYS]: {
    title: 'Paid Sick Days',
    description: 'Feeling sick? Company covers you during your sick days. *Limits may apply. ',
    icon: Icons.PAID_SICK_DAYS
  },
  [JobBenefits.MATERNITY_LEAVE]: {
    title: 'Paid Maternity/Paternity Leave',
    description: 'Company offers parental leave for all newborn children. ',
    icon: Icons.MATERNITY_LEAVE
  },
  [JobBenefits.WELLNESS_BENEFITS]: {
    title: 'Wellness benefits',
    description: 'Company covers or copays for mental or physical health activities.',
    icon: Icons.WELLNESS_BENEFITS
  },
  [JobBenefits.PAID_TIME_OFF]: {
    title: 'Paid time off',
    description: 'Company offers 15 days or more of paid time off.',
    icon: Icons.PAID_TIME_OFF
  },
  [JobBenefits.REMOTE_OFFICE_BUDGET]: {
    title: 'Remote Office Budget',
    description: 'Get a budget for building your dream remote office.',
    icon: Icons.REMOTE_OFFICE_BUDGET
  },
  [JobBenefits.INTERNET_ALLOWANCE]: {
    title: 'Internet allowance',
    description: 'Covered costs for internet or budget for internet allowance.',
    icon: Icons.INTERNET_ALLOWANCE
  },
  [JobBenefits.VACATION_BIRTHDAY]: {
    title: 'Vacation on birthday',
    description: 'Your birthday counts as an extra day of time off.',
    icon: Icons.BIRTHDAY_VACATION
  },
  [JobBenefits.FULLY_REMOTE]: {
    title: 'Fully remote',
    description: 'You can work from anywhere in the world.',
    icon: Icons.WORLD
  },
}

