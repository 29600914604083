export enum JobBenefits {
  FLEXIBLE_HOURS = 'FLEXIBLE_HOURS',
  PERFORMANCE_BONUS = 'PERFORMANCE_BONUS',
  RETIREMENT_PLAN = 'RETIREMENT_PLAN',
  CHILDCARE_ASSISTANCE = 'CHILDCARE_ASSISTANCE',
  HEALTH_COVERAGE = 'HEALTH_COVERAGE',
  COMPUTER = 'COMPUTER',
  COMPANY_RETREATS = 'COMPANY_RETREATS',
  DIGITAL_LIBRARY = 'DIGITAL_LIBRARY',
  EDUCATION_STIPEND = 'EDUCATION_STIPEND',
  EQUITY = 'EQUITY',
  PAID_SICK_DAYS = 'PAID_SICK_DAYS',
  MATERNITY_LEAVE = 'MATERNITY_LEAVE',
  WELLNESS_BENEFITS = 'WELLNESS_BENEFITS',
  PAID_TIME_OFF = 'PAID_TIME_OFF',
  REMOTE_OFFICE_BUDGET = 'REMOTE_OFFICE_BUDGET',
  INTERNET_ALLOWANCE = 'INTERNET_ALLOWANCE',
  VACATION_BIRTHDAY = 'VACATION_BIRTHDAY',
  FULLY_REMOTE = 'FULLY_REMOTE',
}
